
.grecaptcha-badge {
  display: none !important;
  visibility: hidden !important;
}

a[href^="https://www.froala.com/wysiwyg-editor?"] {
  display: none!important;
}


.ant-tooltip-inner div.ant-typography, .ant-tooltip-inner .ant-typography p {
  margin-bottom: 0;
}

.ant-progress-text {
  width: 2em;
}

.ant-progress-text {
  margin-right: 25px;
}

.site-description-item-profile-wrapper {
  margin-bottom: 7px;
}

.site-description-item-profile-wrapper .ant-typography {
  display: inline-block;
}

[data-theme='compact'] .site-description-item-profile-wrapper {
  font-size: 12px;
  line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
  display: block;
}

[data-theme='compact'] .ant-drawer-body p.site-description-item-profile-p {
  font-size: 14px;
  line-height: 1.66667;
}

.site-description-item-profile-p-label {
  display: inline-block;
  margin-right: 8px;
}
